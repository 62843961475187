const STATUS_ACTIVE = 1;
const STATUS_INACTIVE = 0;

export default {
	STATUS_ACTIVE,
	STATUS_INACTIVE,
	LEVELS: {
		ADMIN: 1,
		MODERATOR: 2,
		SELLER: 3
	},
}