import axios from "../../utils/axios";

class Sidebar {
  static async getModules(role) {
    return new Promise((resolve, reject) => {
      axios.post("admin/modules/get-module", { role: role }).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getModuleByUser(Id) {
    return new Promise((resolve, reject) => {
      axios.post("admin/modules/get-module-user", { Id: Id }).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default Sidebar;
