import React from 'react';
import { connect } from 'react-redux';
import { Constants, Globals } from 'utils';
import { AuthService } from 'services';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps<any> {
	dispatch: any
}

class Verified extends React.Component<Props> {

	async componentDidMount() {
		const res: any = await AuthService.activate({
			code: this.props.match.params.code
		});
		if (!res.result) {
			this.props.history.replace('/');
		}
		else {
			this.props.history.replace('/');
			Globals.showSuccess("Su cuenta ha sido verificada");
		}
	}
	
	render() {
		return null;	
	}
}

export default connect()(Verified);