export default Object.freeze([
  {
    id: 1,
    name: "Inventario",
    icon: "th-large",
    subLinks: [
      { title: 'Vendedores', path: '/sellers' },
      { title: 'Pedidos', path: '/orders' },
      { title: 'Ingresos', path: '/incomes' },
      { title: 'Cuentas por cobrar', path: '/accounts-receivable' },
      { title: 'Cuentas por pagar', path: '/accounts-to-pay' },
      { title: 'Compras', path: '/purchases' },
      { title: 'Cotizaciones', path: '/quotes' },
      { title: 'Regiones', path: '/zones' },
      { title: 'Marcas', path: '/marks' },
      { title: 'Categorias', path: '/categories' },
      { title: 'Categorias (ingresos y gastos)', path: '/incomes-categories' },
      { title: 'Modelos', path: '/models' },
    ]
  },
  {
    id: 2,
    name: "Ventas",
    icon: "balance-scale",
    subLinks: [
      { title: 'Clientes', path: '/clients' },
      { title: 'Pedidos', path: '/orders' },
    ]
  },
  {
    id: 3,
    name: "Compras",
    icon: "credit-card",
    subLinks: []
  },
  {
    id: 4,
    name: "Utilidades",
    icon: "fax",
    subLinks: [
      { title: 'Clientes', path: '/clients' },
      { title: 'Gerentes', path: '/managers' },
      { title: 'Comisiones', path: '/comissions' }
    ]
  },
  {
    id: 5,
    name: "Reportes",
    icon: "envelope",
    subLinks: []
  },
  {
    id: 6,
    name: "Cuentas por Cobrar",
    icon: "dollar",
    subLinks: []
  },
  {
    id: 7,
    name: "Gastos",
    icon: "dollar",
    subLinks: []
  },
  {
    id: 8,
    name: "Pagos",
    icon: "dollar",
    subLinks: []
  },
  {
    id: 9,
    name: "Bancos",
    icon: "bank",
    subLinks: []
  }
]);
