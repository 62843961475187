import React from "react";
import { connect } from "react-redux";

import { Globals, Constants, Menus } from "../../utils";
import { Sidebar, AuthService } from "../../services";
import AsideNavbar from "./aside_navbar.js";
import Logo from '../../assets/img/logo.png';
import { Icon } from "components";

// import Socket from '../utils/socket';
class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSidebar: false,
      menu: [],
      // active: "Usuarios"
    };
  }

  componentDidMount() {
    const { sidebar } = this.props;
    this.maybeLoadMenu(sidebar);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sidebar !== this.props.sidebar && this.props.sidebar) {
      this.maybeLoadMenu(this.props.sidebar);
    }
  }

  maybeLoadMenu = sidebar => {
    let sidebarNav = {
      Inventario: [],
      Ventas: [],
      Compras: [],
      Utilidades: [],
      Reportes: [],
      CuentasPorCobrar: [],
      Gastos: [],
      Pagos: [],
      Bancos: []
    };

    if(sidebar && sidebar.length > 0){
      sidebar
      .filter(Item => Item.status === Constants.STATUS_ACTIVE)
      .forEach(Item => {
        if (Item.menu_id === Menus[1].id) {
          sidebarNav["Inventario"].push(Item);
        } else if (Item.menu_id === Menus[2].id) {
          sidebarNav["Ventas"].push(Item);
        } else if (Item.menu_id === Menus[3].id) {
          sidebarNav["Compras"].push(Item);
        } else if (Item.menu_id === Menus[4].id) {
          sidebarNav["Utilidades"].push(Item);
        } else if (Item.menu_id === Menus[5].id) {
          sidebarNav["Reportes"].push(Item);
        } else if (Item.menu_id === Menus[6].id) {
          sidebarNav["CuentasPorCobrar"].push(Item);
        } else if (Item.menu_id === Menus[7].id) {
          sidebarNav["Gastos"].push(Item);
        } else if (Item.menu_id === Menus[8].id) {
          sidebarNav["Pagos"].push(Item);
        } else if (Item.menu_id === Menus[9].id) {
          sidebarNav["Bancos"].push(Item);
        }
      });
    }
    

    this.setState({ menu: sidebarNav, showMenu: true });
  };

  renderName = () => {
    const { user } = this.props;
    let name = "";
    if (user) {
      if (user.person) {
        name = user.person.first_name;
      }
      return name;
    }
  };
  renderCode = () => {
    const { user } = this.props;
    let code;
    if (user) {
      if (user.person) {
        code = user.person.code;
      }
      return code;
    }
  };

  logout = () => {
    const { user } = this.props;
    Globals.confirm("¿Desea cerrar sesión?", () => {
      Globals.setLoading();

      AuthService.logout(user)
      .then(({ result }) => {
        if(result){
          setTimeout(() => {
            this.props.dispatch({
              type: "QUIT_USER"
            });

            if (this.props.admin) {
              this.props.dispatch({
                type: "QUIT_ADMIN"
              });
            }
    
            this.props.dispatch({
              type: "QUIT_SIDEBAR"
            });
    
            this.props.dispatch({
              type: "REMOVE_TOKEN"
            });
    
            this.props.dispatch({
              type: 'SET_SALES',
              payload: null
            });
    
            this.props.dispatch({
              type: 'SET_INVENTORY',
              payload: null
            });
    
            this.props.dispatch({
              type: 'SET_DELIVERY',
              payload: null
            });
    
            this.props.history.push("/login");
    
            Globals.quitLoading();
          }, 1500);
        }
      })
      .catch((error) => {
        return Globals.showError('Error: ', error);
      })

      
    });
  };

  backAdmin = () => {
    let message = "¿Desea regresar como administrador?";
    if (this.props.admin.role === 2)
      message = "¿Desea regresar como superadministrador?";

    Globals.confirm(message, () => {
      Globals.setLoading();

      this.props.dispatch({
        type: "SET_USER",
        payload: this.props.admin
      });

      setTimeout(() => {
        Sidebar.getModules(this.props.admin.role)
          .then(response => {
            this.props.dispatch({
              type: "SET_SIDEBAR",
              payload: response
            });

            this.props.dispatch({
              type: 'SET_SALES',
              payload: null
            });

            this.props.dispatch({
              type: 'SET_INVENTORY',
              payload: null
            });

            this.props.dispatch({
              type: 'SET_DELIVERY',
              payload: null
            });

            if (this.props.admin.role === 2 || this.props.admin.role === 1) {
              this.props.history.push("/enterprises");
            }
          })
          .catch()
          .finally(() => {
            Globals.quitLoading();
          });
      }, 1000);
    });
  };

  searchPath = path => {
    let pathC = this.props.user.permissions.find(i => i.path === path);
    if (pathC) return true;
    else return false;
  };

  toggleSidebar = event => {
    let key = `${event.currentTarget.parentNode.id}Open`;
    this.setState({ [key]: !this.state[key] });
  };

  handleVisibility = () => {
    this.setState((state) => ({
      ...state,
      showSidebar: !state.showSidebar
    }))
  }

  /* setActive = (Item) => {
    console.log(Item)
    this.setState({ active: Item.name });
  } */

  render() {
    const { user, admin } = this.props;
    const { showSidebar } = this.state;

    return (
      <div id="menu" className="menu">
        <div className="menu-header-wrapper">
          <header className="menu-header">
            <div className="header-logo">
              <img src={Logo} alt="SYSGE24"/>
            </div>
            <div className="header-filling">
              <div className="header-visibility-button">
              <button className="icon-buttons" onClick={this.handleVisibility}>
                <span>
                  <Icon name="bars" />
                </span>
              </button>
              </div>
            </div>
          </header>
        </div>
        <main id="id" className="dashboard">
          <AsideNavbar 
            handleLogout={this.logout}
            handleBackAdmin={this.backAdmin}
            show={showSidebar}
            user={user}
            admin={admin}
          />
          <div className="dashboard-content">
            {this.props.children}
          </div>
        </main>
      </div>
    );
  }
}

export default connect(state => {
  return {
    user: state.user,
    support: state.support,
    admin: state.admin,
    sidebar: state.sidebar,
    inventory: state.inventory,
    sales: state.sales,
    delivery: state.delivery
  };
})(Menu);
