import HoldOn from 'react-hold-on';
import Swal from 'sweetalert2';
import moment from 'moment';
//import Colors from "./colors";
import $ from 'jquery';

class Globals {

	setLoading = () => {
		HoldOn.open({
		     theme: "sk-bounce"
		});
	}

	quitLoading = () => {
		HoldOn.close();
	}

	formatMiles = (n: any, decimals: boolean = true, currency: string = '') => {
		var c: any = isNaN(c = Math.abs(c)) ? 2 : c,
			d: any = d == undefined ? "," : d,
			t: any = t == undefined ? "." : t,
			s: any = n < 0 ? "-" : "",
			i: any = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
			j: any = (j = i.length) > 3 ? j % 3 : 0;

		return currency + ' ' + s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
	}

	hideTooltip = () => {
		$('button').blur();
    }

	showSuccess = (message: string) => {
		Swal.fire({
            title: "",
            text: message,
            type: "success",
			showCancelButton: false,
			confirmButtonColor: '#0b0b51',
        });
	}

	showWarning = (message?: string) => {
		Swal.fire({
		  title: "",
		  text: message,
		  type: "warning",
		  showCancelButton: false
		});
	  };

	showError = (message?: string) => {
		Swal.fire({
            title: "",
            text: message ? message : "Se ha producido un error",
            type: "error",
			showCancelButton: false,
			confirmButtonColor: '#0b0b51',
        });
	}

	confirm = (message: string,callback: any) => {
		this.hideTooltip();
		Swal.fire({
            title: "",
            text: message,
            type: "warning",
			showCancelButton: true,
            confirmButtonText: 'Aceptar',
			cancelButtonText: 'Cancelar',
			confirmButtonColor: '#0b0b51',
        }).then((confirm: any) => {
        	if (confirm.value) {
        		callback();
        	}
        });
	}

	getDate = (date: string, to: string = 'DD/MM/YYYY', from: string = 'YYYY-MM-DD HH:mm:ss') => {
		return moment(date,from).format(to);
	}

	getStatus = (status: number, custom_value: null, custom_text: String, custom_color: string) => {
		let respuesta;
		switch (status) {
		  case 0:
			respuesta = [
				{
					text: 'Nuevo',
					color: '#e53935'
				}
			]
			break;
		  case 1:
			respuesta = [{
			  text: "Activo",
			  color:  '#43A047'
			}];
			break;
		  case 2:
			respuesta = [{
			  text: "Suspendido",
			  color: '#FB8C00'
			}];
			break;
		  case 3:
			respuesta = [{
			  text: "Eliminado",
			  color: '#e53935'
			}]
			break;
		  case custom_value:
			respuesta = [{
			  text: custom_text,
			  color: custom_color
			}];
			break;
		  default:
			break;
		}
		return respuesta;
	}

	capitalize = (text: string) => {
		return text.charAt(0).toUpperCase() + text.slice(1);
	}

	pad(num: number, size: number = 4) {
	    var s = num + "";
	    while (s.length < size) s = "0" + s;
	    return s;
	}

	isPdf(file: string) {
		return file.substr(file.lastIndexOf('.') + 1) == 'pdf';
	}

	soloNumeros = (e: any) => {
		var key = window.Event ? e.which : e.keyCode;
		var condition = key >= 48 && key <= 57;
		if (!condition) {
		  e.preventDefault();
		}
	};
}

export default new Globals();