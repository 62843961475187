import Calendar from './calendar.svg';
import Search from './search.svg';
import PaperclipIcon from './paperclip.png';
import ArrowLeft from './arrow-left.svg';
import ArrowLeftWhite from './arrow-left-white.png';
import UserIcon from './user.png';

// Menu
import MenuLogout from './menu/logout.png';
import MenuLogoutWhite from './menu/logout-white.png';
import MenuAdvertisingsWhite from './menu/advertisings-white.png';
import MenuAdvertisings from './menu/advertisings.png';
import MenuGeneratorsWhite from './menu/generators-white.png';
import MenuGenerators from './menu/generators.png';
import MenuCommissionsWhite from './menu/commissions-white.png';
import MenuCommissions from './menu/commissions.png';
import MenuModeratorsWhite from './menu/moderators-white.png';
import MenuModerators from './menu/moderators.png';
import MenuEarningsWhite from './menu/earnings-white.png';
import MenuEarnings from './menu/earnings.png';
import MenuTransactionsWhite from './menu/transactions-white.png';
import MenuTransactions from './menu/transactions.png';

const Menu = {
	MenuLogout,
	MenuLogoutWhite,
	MenuAdvertisingsWhite,
	MenuAdvertisings,
	MenuGeneratorsWhite,
	MenuGenerators,
	MenuCommissionsWhite,
	MenuCommissions,
	MenuModeratorsWhite,
	MenuModerators,
	MenuEarningsWhite,
	MenuEarnings,
	MenuTransactionsWhite,
	MenuTransactions
}

export {
	Calendar,
	Search,
	Menu,
	ArrowLeft,
	UserIcon,
}