import { Icon } from 'components';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class AccordionDropdown extends Component {

	constructor(props) {
		super(props);

		this.state = {
			active: false,
		};
	}

	handleDropdown = () => {
		this.setState(state => ({
			active: !state.active
		}));
	};

	render() {
		const buttonClass = this.state.active ? "active" : "";
		const dropdownClass = this.state.active ? "show" : "hide";
		const iconArrowClass = this.state.active ? "rotate" : "";

		return (
			<div className="nav-item">
				<button 
					className={`dropdown-button ${buttonClass}`}
					onClick={this.handleDropdown}
					aria-expanded={this.state.active}
				>
					<span className="button-text">
						{this.props.children}
					</span>
					<span className={`button-arrow-icon ${iconArrowClass}`}>
						<Icon name="chevron-up" />
					</span>
				</button>
				<ul
					className={`sub-menu-wrapper ${dropdownClass}`} 
				>
					{this.props.links.map(({ path, title })=> (
						<li 
							className="sub-menu-item"
							key={title}
						>
							<NavLink to={path} activeClassName="active">
								{title}
							</NavLink>
						</li>
					))}
				</ul>
			</div>
		);
	}
}

export default AccordionDropdown;
