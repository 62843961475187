import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import {
  HashRouter as Router,
  Switch,
  Redirect
} from "react-router-dom";
import "moment/locale/es";
import { Route } from "components";


// Routes
import Home from "screens/home";
import Profile from "screens/profile";
import Login from "./screens/login/login";
import Clients from "./screens/clients/clients";
import Sellers from "./screens/sellers/sellers";
import Orders from "./screens/orders/orders";
import Incomes from "./screens/incomes/incomes";
import AccountsReceivable from "screens/accounts-receivable/accounts-receivable";
import accountsToPay from "screens/accounts-to-pay/accounts-to-pay";
import purchases from "screens/purchases/purchases";
import Comissions from "screens/comissions/comissions";
import Managers from "screens/managers/managers";
import Quotes from "screens/quotes/quotes";
import Zones from "screens/zones/zones";
import Marks from "screens/marks/marks";
import categories from "screens/categories/categories";
import incomesCategories from "screens/incomes-categories/incomes-categories";
import Models from "screens/models/models";

// Auth
import Verified from "screens/auth/login/verified";
import Reset from "screens/auth/login/reset";

// Scripts
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import { emit } from "jetemit";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./scss/main.scss";
import { Manager } from "socket.io-client";

const Container = () => {
  return (
    <Switch>
      <Route exact path="/" component={ Home } />
      <Route exact path="/recover/:code" component={ Reset } />
      <Route exact path="/activate/:code" component={ Verified } />
      {/*<Route exact path="/profile" component={ Profile } />

      <Route exact path="/clients" component={ Clients } />
      <Route exact path="/orders" component={ Orders } />
      <Route exact path="/sellers" component={ Sellers } />
      <Route exact path="/incomes" component={ Incomes } />
      <Route exact path="/accounts-receivable" component={ AccountsReceivable } />
      <Route exact path="/accounts-to-pay" component={ accountsToPay} />
      <Route exact path="/purchases" component={ purchases } />
      <Route exact path="/comissions" component={ Comissions } />
      <Route exact path="/managers" component={ Managers } />
      <Route exact path="/quotes" component={ Quotes } />
      <Route exact path="/zones" component={ Zones } />
      <Route exact path="/marks" component={ Marks } />
      <Route exact path="/categories" component={ categories } />
      <Route exact path="/incomes-categories" component={ incomesCategories } />
      <Route exact path="/models" component={ Models } />*/}

      <Redirect to="/" />
    </Switch>
  )
}

const App = () => (
  <Provider store={ store }>
    <PersistGate loading={ null } persistor={ persistor }>
      <Router>
        <Container />
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
