import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { Constants } from 'utils';
import { Redirect } from 'react-router-dom';
import { Logo } from 'assets/img';

const Home = () => {
	// const user = useSelector((state: RootState) => state.user);

	// if (!user) {
	// 	return <Redirect to="/login" />
	// }
	// else {
	// 	return <Redirect to="/profile" />

	// }

	return (
		<div style={{
			height: '100vh',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		}}>
			<img src={ Logo } style={{
				width: 400,
				marginBottom: 50,
				objectFit: 'contain'
			}} />
		</div>
	)
}

export default Home;