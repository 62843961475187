import { Api } from 'utils';

class AuthService {
	
	static activate = async (params = {}) => Api.createResponse('auth/activate',params);
	static reset = async (params = {}) => Api.createResponse('auth/reset',params);
	static checkCode = async (params = {}) => Api.createResponse('auth/check-code',params);

	static authenticate = async (form = {}) => Api.createResponse('auth/login', form);

	static logout = async (form = {}) => Api.createResponse('auth/logout', form);

	// static async authenticate(form) {
	// 	return new Promise((resolve, reject) => {
	// 	  axios.post("login", form).then(
	// 		({ data }) => {
	// 		  // http success
	// 		  resolve(data);
	// 		},
	// 		({ response }) => {
	// 		  const { data } = response;
	// 		  // http failed
	// 		  reject(data);
	// 		}
	// 	  );
	// 	});
	//   }
	
}

export default AuthService;