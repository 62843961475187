import React, { Component } from 'react';

import AccordionDropdown from './accordion_dropdown';
import { Icon } from 'components';
import MenuLinks  from '../../utils/menus';

class AsideNavbar extends Component {

	render() {
		const show = this.props.show ? 'show' : '';
		const { admin, user } = this.props;
		const { level: { name, code } } = user;


		return (
			<aside className={`sidebar ${show}`}>
				<section className="sidebar-user-actions">
					<div className="sidebar-logout-buttons">
						<button className="icon-buttons" onClick={this.props.handleLogout}>
							<span>
								<Icon name="sign-out"/>
							</span>
						</button>
						{admin && admin.id !== user.id && (
							<button 
								className="icon-buttons"
								onClick={this.props.handleBackAdmin}	
							>
								<span>
									<Icon name="power-off"/>
								</span>
							</button>
						)}
					</div>
				</section>
				<section className="sidebar-user-links">
					<ul className="user-info">
						<li className="user-name">{name}</li>
						<li className="user-code">Codigo: {code}</li>
					</ul>
					<div className="links-wrapper">
						<nav className="sidebar-nav-links">
							{MenuLinks.map(({ id, name, icon, subLinks }) => (
								<AccordionDropdown
									links={subLinks}
									key={`${name}-${id}`}
								>
									<Icon name={icon} />
									<span>{name}</span>
								</AccordionDropdown>
							))}
						</nav>
					</div>
				</section>
			</aside>
		)
	}
};

export default AsideNavbar;
